<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-form ref="codeForm" v-if="!payOnline" v-model="validCode">
        <span class="label">ادخل الكود المرسل لرقمك</span>
        <!-- :error-messages="codeErrors" -->
        <v-text-field
          v-model="verifyData.verification_code"
          label="الكود"
          solo
          :rules="[validationRules.required]"
          style="margin-bottom: 15px"
        ></v-text-field>
        <a @click="sendVerifyCode()" class="btn complete-btn">ارسال الكود</a>
      </v-form>
      <!-- <div v-for="product in products" :key="product">
            {{product.product_id}} {{product.quantity}}
        </div>

        {{cartSent}}
        {{shipping_to}} -->

      <v-snackbar v-model="snackbar" color="success" top right>
        {{ completeCartMassege }}
      </v-snackbar>
      <v-snackbar v-model="snackbar1" color="red" top right>
        {{ completeCartMassege }}
      </v-snackbar>
      <v-snackbar v-model="snackbar2" color="success" top right>
        {{ validateMassege }}
      </v-snackbar>
      <v-snackbar v-model="snackbar3" color="red" top right>
        {{ validateMassege }}
      </v-snackbar>
      <v-snackbar v-model="snackbar4" color="red" top right>
        {{ completeSignUpMassege }}
      </v-snackbar>
    </v-col>
    <v-row justify="center" align="center">
      <v-col>
        <v-form
          ref="form"
          id="payment_form"
          class="payment-form"
          action="https://api.moyasar.com/v1/payments.html"
          method="POST"
          v-if="payOnline"
          v-model="valid"
        >
          <h2 class="text-center">الدفع</h2>
          <input
            type="hidden"
            name="callback_url"
            :value="'https://smooalnakhel.com/payments/redirect/' + payment_id"
          />
          <input
            type="hidden"
            name="publishable_api_key"
            value="pk_test_wN6smrbt6zp7HvaHz56KenxVSihwpVefQbJft7RK"
          />
          <input
            type="hidden"
            name="amount"
            :value="parseInt(totalPrice * 100)"
          />
          <input type="hidden" name="description" value="Mothaber Payment" />
          <input type="hidden" name="source[type]" value="creditcard" />
          <!-- <input
                  type="hidden"
                  name="source[company]"
                  :value="paymentData.payment_method"
                /> -->
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <label for="nickname">طريقة الدفع *</label>
              <!-- v-model="studentRegistration.payment_method" -->
              <v-radio-group row>
                <v-radio label="Visa" value="visa" checked> </v-radio>
                <v-radio label="masterCard" value="mastercard"> </v-radio>
                <v-radio label="mada" value="mada"> </v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <label for="name"> رقم البطاقة *</label>
              <v-text-field
                name="source[number]"
                solo
                :rules="[validationRules.required]"
                v-model="paymentData.card_number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <label for="name">اسم حامل البطاقة *</label>
              <v-text-field
                name="source[name]"
                solo
                :rules="[validationRules.required]"
                v-model="paymentData.card_holder_name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <label for="name">كود cvc *</label>
              <v-text-field
                name="source[cvc]"
                solo
                :rules="[validationRules.required]"
                v-model="paymentData.cvc"
              ></v-text-field>
            </v-col>

            <v-col cols="6" sm="3">
              <label for="month">الشهر *</label>
              <v-select
                name="source[month]"
                :items="months"
                solo
                :rules="[validationRules.required]"
                v-model="paymentData.exp_month"
              ></v-select>

              <!-- v-model="studentRegistration.exp_month" -->
            </v-col>
            <v-col cols="6" sm="3">
              <label for="year">السنة *</label>
              <v-select
                name="source[year]"
                solo
                :items="years"
                :rules="[validationRules.required]"
                v-model="paymentData.exp_year"
              ></v-select>
            </v-col>
            <v-col class="text-center">
              <a class="btn complete-btn" @click.prevent="submitPayment">دفع</a>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-row>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { mapGetters } from "vuex";
// import { validationMixin } from "vuelidate";
import { validationMixin } from "../mixins/validationMixin";
// eslint-disable-next-line no-unused-vars
import { required, minLength, tel } from "vuelidate/lib/validators";

import { ServiceFactory } from "../services/ServiceFactory";
const service = ServiceFactory.get("register");
const cartService = ServiceFactory.get("cart");
// const productService = ServiceFactory.get("product");
export default {
  mixins: [validationMixin],

  validations: {
    verifyData: {
      verification_code: { required, minLength: minLength(4) },
    },
  },
  data: () => ({
    verifyData: {
      verification_code: "",
      mobile: "",
    },
    snackbar: false,
    snackbar1: false,
    snackbar2: false,
    snackbar3: false,
    snackbar4: false,
    completeCartMassege: "",
    validateMassege: "",
    completeSignUpMassege: "",
    dialog: false,
    dialog1: false,
    allProducts: [],
    payOnline: false,
    payment_id: null,
    months: [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ],
    years: [],
    paymentData: {
      card_number: "",
      card_holder_name: "",
      cvc: "",
      exp_month: "",
      exp_year: "",
    },
    valid: false,
    validCode: false,
  }),
  computed: {
    phoneNumber() {
      return this.$store.state.phoneNumber.phone;
    },
    totalPrice() {
      return this.$store.state.totalPrice;
    },
    shipping_to() {
      return this.$store.state.shipping_to;
    },
    products() {
      return this.$store.state.products;
    },
    order_type() {
      return this.$store.state.order_type;
    },
    paymentMethod() {
      if (this.$store.state.shipping_to.paymentMethod === "فيزا") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return (this.$store.state.shipping_to.paymentMethod = 3);
      } else if (this.$store.state.shipping_to.paymentMethod === "كاش") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return (this.$store.state.shipping_to.paymentMethod = 1);
      }
      return this.$store.state.shipping_to.paymentMethod;
    },
    discount() {
      return this.$store.state.discount;
    },
    // codeErrors() {
    //   const errors = [];
    //   if (!this.$v.verifyData.verification_code.$dirty) return errors;
    //   !this.$v.verifyData.verification_code.required &&
    //     errors.push("يجب ادخال كود خصم");
    //   !this.$v.verifyData.verification_code.minLength &&
    //     errors.push("يجب ادخال كود خصم صالح");
    //   return errors;
    // },
    cartSent() {
      for (var i = 0, len = this.products.length; i < len; i++) {
        delete this.products[i].total;
        delete this.products[i].Kg;
        delete this.products[i].price;
        delete this.products[i].product_name;
      }
      this.products.map((item) => (item.options = []));
      return true;
    },
  },
  methods: {
    generateYears() {
      const currentYear = new Date().getFullYear();
      for (let i = currentYear; i <= currentYear + 8; i++) {
        this.years.push(i.toString());
      }
    },
    async sendVerifyCode() {
      if (!this.validCode) {
        this.$refs.codeForm.validate();
        return;
      }
      this.dataLoading = true;
      this.verifyData.mobile = this.phoneNumber;
      const signup = await service.sendVerifyCode(this.verifyData);
      if (signup.status.error === false) {
        localStorage.setItem("token", signup.data.accessToken);
        localStorage.setItem("mobile", signup.data.mobile);
        // const creatAddress = await cartService.creatAddress(
        //   signup.user.accessToken,
        //   this.shipping_to
        // );
        this.cartSent;
        const allData = {
          products: this.products,
          shipping_to: this.shipping_to,
          order_type: this.order_type,
          payment_method: this.paymentMethod,
          coupon_code: this.discount,
        };
        const completeCart = await cartService.checkOut(allData);
        if (completeCart.status.error === false) {
          if (completeCart.data.payment_id) {
            this.payOnline = true;
            this.payment_id = completeCart.data.payment_id;
          } else {
            this.completeCartMassege = "Cart Success";
            this.snackbar = true;
            this.$router.push("/myOrders");
          }
        } else {
          this.completeCartMassege = completeCart.status.message;
          this.snackbar1 = true;
        }
      } else {
        console.log("signup.message", signup.status.message);
        this.completeSignUpMassege = signup.status.message;
        this.snackbar4 = true;
      }
      this.dataLoading = false;
    },
    submitPayment() {
      if (this.valid) {
        document.getElementById("payment_form").submit();
      } else {
        this.$refs.form.validate();
      }
    },
  },
  created() {
    this.generateYears();
  },
};
</script>
<style>
.v-text-field.v-text-field--solo .v-label {
  left: auto !important;
  right: 0 !important;
}
.v-application--is-ltr .v-messages {
  text-align: right;
}
.complete-btn {
  display: block;
  text-align: center;
  background-color: #4c9e53;
  color: #fff !important;
  border-radius: 37px;
  padding: 9px;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  border: none;
  font-family: "frutiger lt arabic 55 roman" !important;
}
</style>
