<template>
  <v-row>
    <v-col>
      <div class="text-center">
        <vuetify-logo />
      </div>
      <div class="slider-section">
        <verify :auth="auth" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import VuetifyLogo from "../components/VuetifyLogo.vue";
import verify from "../components/verify-phone.vue";

export default {
  props: ["auth"],
  components: {
    // Logo,
    VuetifyLogo,
    verify,
  },
};
</script>
<style>
/* .section {
  padding: 20px 0;
} */
</style>
